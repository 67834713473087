import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Terms from "./style"
export default function InformationPolicy() {
  return (
    <Terms>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-7 col-lg-8 col-md-11 text-center">
            <Terms.Box>
              <Terms.Title as="h2">Information Policy</Terms.Title>
            </Terms.Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-xxl-8 col-xl-9 col-lg-10">
            <Terms.Content>
              <Terms.Text>
                This Information Policy outlines the guidelines and rules for
                the effective management, protection, and responsible use of
                information within WarrantyWorx. By accessing or using
                WarrantyWorx, you agree to adhere to the practices described in
                this Information Policy.
              </Terms.Text>

              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Purpose</Terms.TitleSmall>
                <Terms.Text>
                  The purpose of this Information Policy is to establish
                  guidelines for the effective management, protection, and
                  responsible use of information within WarrantyWorx. This
                  policy is designed to ensure the confidentiality, integrity,
                  and availability of information assets.
                </Terms.Text>
              </Terms.ContentBox>

              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Scope</Terms.TitleSmall>
                <Terms.Text>
                  This policy applies to all employees, contractors, and third
                  parties who have access to information owned or controlled by
                  WarrantyWorx.
                </Terms.Text>
              </Terms.ContentBox>

              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  Information Classification
                </Terms.TitleSmall>
                <Terms.Text>
                  - Confidential Information: Information that is sensitive and
                  requires the highest level of protection to prevent
                  unauthorized disclosure. This includes, but is not limited to,
                  customer data, financial information, and proprietary business
                  data.
                  <br />
                  - Internal Information: Information intended for internal use
                  only, not classified as confidential, but still requiring
                  protection.
                  <br />- Public Information: Information that can be freely
                  shared with the public.
                </Terms.Text>
              </Terms.ContentBox>

              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Responsibilities</Terms.TitleSmall>
                <Terms.Text>
                  - Data Owners: Business units or individuals responsible for
                  the creation, accuracy, and maintenance of specific
                  information assets.
                  <br />
                  - Data Custodians: Individuals or departments responsible for
                  the protection, storage, and maintenance of information.
                  <br />- Users: Individuals granted access to information and
                  responsible for its appropriate use.
                </Terms.Text>
              </Terms.ContentBox>

              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Access Controls</Terms.TitleSmall>
                <Terms.Text>
                  - Access Rights: Access to information is granted based on job
                  responsibilities and a need-to-know basis.
                  <br />- User Authentication: Users must use unique,
                  confidential login credentials to access information systems.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  Information Security
                </Terms.TitleSmall>
                <Terms.Text>
                  - Data Encryption: Confidential information transmitted
                  over networks must be encrypted to protect against
                  unauthorized access.
                  <br />- Malware Protection: All information systems must
                  have up-to-date antivirus and anti-malware protection.
                </Terms.Text>
              </Terms.ContentBox>

              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  Data Handling and Storage
                </Terms.TitleSmall>
                <Terms.Text>
                  - Data Retention: Information should be retained only as
                  long as necessary for business purposes and in compliance with
                  legal requirements.
                  <br />- Secure Disposal: When information is no longer
                  needed, it should be securely deleted or disposed of in a
                  manner that prevents unauthorized access.
                </Terms.Text>
              </Terms.ContentBox>

              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Incident Reporting</Terms.TitleSmall>
                <Terms.Text>
                  Employees must promptly report any unauthorized access,
                  disclosure, or loss of information to the designated IT or
                  security personnel.
                </Terms.Text>
              </Terms.ContentBox>

              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Compliance</Terms.TitleSmall>
                <Terms.Text>
                  All information activities must comply with applicable laws,
                  regulations, and contractual obligations.
                </Terms.Text>
              </Terms.ContentBox>

              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  Training and Awareness
                </Terms.TitleSmall>
                <Terms.Text>
                  Employees must receive training on this Information Policy and
                  their responsibilities for information security.
                </Terms.Text>
              </Terms.ContentBox>

              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  Policy Review and Updates
                </Terms.TitleSmall>
                <Terms.Text>
                  This Information Policy will be reviewed regularly and updated
                  as necessary to address emerging risks and changes in business
                  operations.
                </Terms.Text>
              </Terms.ContentBox>

              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Enforcement</Terms.TitleSmall>
                <Terms.Text>
                  Violations of this policy may result in disciplinary action,
                  up to and including termination of employment.
                </Terms.Text>
              </Terms.ContentBox>

              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">Contact Information</Terms.TitleSmall>
                <Terms.Text>
                  For questions or concerns regarding this Information Policy,
                  please contact Kyle@Warranty-Worx.com.
                </Terms.Text>
              </Terms.ContentBox>
            </Terms.Content>
          </Col>
        </Row>
      </Container>
    </Terms>
  )
}
