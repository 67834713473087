import React from "react"
import { PageWrapper } from "~components/Core"

import FooterSection from "~sections/utility/Footer"
import FooterFive from "~sections/agency/FooterFive"
import InformationPolicy from "~sections/utility/TermsCondition/InformationPolicy"
const header = {
  headerClasses: "site-header site-header--menu-start light-header",
  containerFluid: false,
}

export default function TermsAndConditions() {
  return (
    <PageWrapper innerPage={true}>
      <InformationPolicy />
      <FooterFive />
    </PageWrapper>
  )
}
